<template>
    <div class="mr-10 ml-10">
        <TitleBar
            :disabled="loading"
            :loading="loading"
            :menu="[{text: this.$t('invoice.payment.receipt'), callback: createReceipt},]"
        >{{ $t("invoice.payment.update") }}
        </TitleBar>

        <PaymentDetails
            :invoice="this.$route.params.uuid"
            :payment="this.$route.params.id"
        ></PaymentDetails>
    </div>
</template>

<script>
import PaymentDetails from "@/components/Invoices/PaymentDetails.vue";
import TitleBar from "@/components/TitleBar.vue";
import Accounting from "@/helpers/Accounting";

export default {
    name: "EditPayment",
    components: {TitleBar, PaymentDetails},
    data() {
        return {
            loading: false
        };
    },
    methods: {
        async createReceipt() {
            this.loading = true;
            let details = await Accounting.get("/incomes/invoices/" + this.$route.params.uuid).catch((error) => {
                if (error.response) {
                    let errors = [];
                    for (const errorElement of error.response.data.errors) {
                        errors.push({
                            type: "error",
                            text: errorElement
                        });
                    }
                    this.$store.commit("SET_ALERTS", errors);
                } else if (error.request) {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: this.$t("api.no_response")
                    }]);
                } else {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: error.message
                    }]);
                }
            });

            if (this.$store.state.company.uuid == null) {
                this.$store.commit("SET_ALERTS", [{
                    type: "error",
                    text: this.$t("receipt.company_not_found")
                }]);
            }

            for (const paymentElement of details.data["payments"]) {
                if (paymentElement.uuid === this.$route.params.id) {
                    Accounting.post("/receipts", {
                        "from": details.data.contact,
                        for: this.$store.state.company.uuid,
                        currency: details.data.currency,
                        payment_date: paymentElement.paid_at,
                        payment_method: paymentElement.payment_method,
                        invoice: this.$route.params.uuid,
                        description: details.data.notes,
                        items: details.data.items
                    }).then(response => {

                        this.loading = false;
                        this.$store.commit("SET_ALERTS", [{
                            type: "success",
                            text: this.$t("receipt.added")
                        }]);
                        this.$router.push("/receipts/" + response.data.uuid);

                    }).catch(error => {
                        if (error.response) {
                            let errors = [];
                            for (const errorElement of error.response.data.errors) {
                                errors.push({
                                    type: "error",
                                    text: errorElement
                                });
                            }
                            this.$store.commit("SET_ALERTS", errors);
                        } else if (error.request) {
                            this.$store.commit("SET_ALERTS", [{
                                type: "error",
                                text: this.$t("api.no_response")
                            }]);
                        } else {
                            this.$store.commit("SET_ALERTS", [{
                                type: "error",
                                text: error.message
                            }]);
                        }
                        this.loading = false;
                    });
                }
            }
        }
    },
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.invoices"),
                to: "/incomes/invoices",
                exact: true
            },
            {
                text: this.$t("invoice.payments"),
                to: "/incomes/invoices/" + this.$route.params.uuid + "/payments",
                exact: true
            },
            {
                text: this.$t("invoice.payment.update"),
                to: "/incomes/invoices/" + this.$route.params.uuid + "/payments/" + this.$route.params.id,
                exact: false
            }
        ]);
    }
};
</script>

<style scoped>

</style>